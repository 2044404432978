export const PHONE_CODES = [
  {
    text: '+1',
    value: 'CA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg',
    name: 'Canada',
    number: 1,
  },
  {
    text: '+1',
    value: 'US',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
    name: 'United States',
    number: 1,
  },
  {
    text: '+7',
    value: 'KZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg',
    name: 'Kazakhstan',
    number: 7,
  },
  {
    text: '+7',
    value: 'RU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg',
    name: 'Russian Federation',
    number: 7,
  },
  {
    text: '+20',
    value: 'EG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg',
    name: 'Egypt',
    number: 20,
  },
  {
    text: '+27',
    value: 'ZA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg',
    name: 'South Africa',
    number: 27,
  },
  {
    text: '+30',
    value: 'GR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg',
    name: 'Greece',
    number: 30,
  },
  {
    text: '+31',
    value: 'NL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg',
    name: 'Netherlands',
    number: 31,
  },
  {
    text: '+32',
    value: 'BE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg',
    name: 'Belgium',
    number: 32,
  },
  {
    text: '+33',
    value: 'FR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg',
    name: 'France',
    number: 33,
  },
  {
    text: '+34',
    value: 'ES',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
    name: 'Spain',
    number: 34,
  },
  {
    text: '+36',
    value: 'HU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg',
    name: 'Hungary',
    number: 36,
  },
  {
    text: '+39',
    value: 'IT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg',
    name: 'Italy',
    number: 39,
  },
  {
    text: '+40',
    value: 'RO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg',
    name: 'Romania',
    number: 40,
  },
  {
    text: '+41',
    value: 'CH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg',
    name: 'Switzerland',
    number: 41,
  },
  {
    text: '+43',
    value: 'AT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg',
    name: 'Austria',
    number: 43,
  },
  {
    text: '+44',
    value: 'GB',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
    name: 'United Kingdom',
    number: 44,
  },
  {
    text: '+45',
    value: 'DK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
    name: 'Denmark',
    number: 45,
  },
  {
    text: '+46',
    value: 'SE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg',
    name: 'Sweden',
    number: 46,
  },
  {
    text: '+47',
    value: 'NO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg',
    name: 'Norway',
    number: 47,
  },
  {
    text: '+47',
    value: 'SJ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg',
    name: 'Svalbard and Jan Mayen',
    number: 47,
  },
  {
    text: '+48',
    value: 'PL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg',
    name: 'Poland',
    number: 48,
  },
  {
    text: '+49',
    value: 'DE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg',
    name: 'Germany',
    number: 49,
  },
  {
    text: '+51',
    value: 'PE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg',
    name: 'Peru',
    number: 51,
  },
  {
    text: '+52',
    value: 'MX',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg',
    name: 'Mexico',
    number: 52,
  },
  {
    text: '+53',
    value: 'CU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg',
    name: 'Cuba',
    number: 53,
  },
  {
    text: '+54',
    value: 'AR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg',
    name: 'Argentina',
    number: 54,
  },
  {
    text: '+55',
    value: 'BR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg',
    name: 'Brazil',
    number: 55,
  },
  {
    text: '+56',
    value: 'CL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg',
    name: 'Chile',
    number: 56,
  },
  {
    text: '+57',
    value: 'CO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg',
    name: 'Colombia',
    number: 57,
  },
  {
    text: '+58',
    value: 'VE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg',
    name: 'Venezuela, Bolivarian Republic of',
    number: 58,
  },
  {
    text: '+60',
    value: 'MY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg',
    name: 'Malaysia',
    number: 60,
  },
  {
    text: '+61',
    value: 'AU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
    name: 'Australia',
    number: 61,
  },
  {
    text: '+61',
    value: 'CX',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg',
    name: 'Christmas Island',
    number: 61,
  },
  {
    text: '+61',
    value: 'CC',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg',
    name: 'Cocos (Keeling) Islands',
    number: 61,
  },
  {
    text: '+62',
    value: 'ID',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg',
    name: 'Indonesia',
    number: 62,
  },
  {
    text: '+63',
    value: 'PH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg',
    name: 'Philippines',
    number: 63,
  },
  {
    text: '+64',
    value: 'NZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg',
    name: 'New Zealand',
    number: 64,
  },
  {
    text: '+65',
    value: 'SG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg',
    name: 'Singapore',
    number: 65,
  },
  {
    text: '+66',
    value: 'TH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg',
    name: 'Thailand',
    number: 66,
  },
  {
    text: '+81',
    value: 'JP',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg',
    name: 'Japan',
    number: 81,
  },
  {
    text: '+82',
    value: 'KR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg',
    name: 'Korea, Republic of',
    number: 82,
  },
  {
    text: '+84',
    value: 'VN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg',
    name: 'Viet Nam',
    number: 84,
  },
  {
    text: '+86',
    value: 'CN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg',
    name: 'China',
    number: 86,
  },
  {
    text: '+90',
    value: 'TR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg',
    name: 'Turkey',
    number: 90,
  },
  {
    text: '+91',
    value: 'IN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg',
    name: 'India',
    number: 91,
  },
  {
    text: '+92',
    value: 'PK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg',
    name: 'Pakistan',
    number: 92,
  },
  {
    text: '+93',
    value: 'AF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg',
    name: 'Afghanistan',
    number: 93,
  },
  {
    text: '+94',
    value: 'LK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg',
    name: 'Sri Lanka',
    number: 94,
  },
  {
    text: '+95',
    value: 'MM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg',
    name: 'Myanmar',
    number: 95,
  },
  {
    text: '+98',
    value: 'IR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg',
    name: 'Iran, Islamic Republic of',
    number: 98,
  },
  {
    text: '+211',
    value: 'SS',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg',
    name: 'South Sudan',
    number: 211,
  },
  {
    text: '+212',
    value: 'MA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg',
    name: 'Morocco',
    number: 212,
  },
  {
    text: '+213',
    value: 'DZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg',
    name: 'Algeria',
    number: 213,
  },
  {
    text: '+216',
    value: 'TN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg',
    name: 'Tunisia',
    number: 216,
  },
  {
    text: '+218',
    value: 'LY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg',
    name: 'Libya',
    number: 218,
  },
  {
    text: '+220',
    value: 'GM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg',
    name: 'Gambia',
    number: 220,
  },
  {
    text: '+221',
    value: 'SN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg',
    name: 'Senegal',
    number: 221,
  },
  {
    text: '+222',
    value: 'MR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg',
    name: 'Mauritania',
    number: 222,
  },
  {
    text: '+223',
    value: 'ML',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg',
    name: 'Mali',
    number: 223,
  },
  {
    text: '+224',
    value: 'GN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg',
    name: 'Guinea',
    number: 224,
  },
  {
    text: '+225',
    value: 'CI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg',
    name: "Côte d'Ivoire",
    number: 225,
  },
  {
    text: '+226',
    value: 'BF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg',
    name: 'Burkina Faso',
    number: 226,
  },
  {
    text: '+227',
    value: 'NE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg',
    name: 'Niger',
    number: 227,
  },
  {
    text: '+228',
    value: 'TG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg',
    name: 'Togo',
    number: 228,
  },
  {
    text: '+229',
    value: 'BJ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg',
    name: 'Benin',
    number: 229,
  },
  {
    text: '+230',
    value: 'MU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg',
    name: 'Mauritius',
    number: 230,
  },
  {
    text: '+231',
    value: 'LR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg',
    name: 'Liberia',
    number: 231,
  },
  {
    text: '+232',
    value: 'SL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg',
    name: 'Sierra Leone',
    number: 232,
  },
  {
    text: '+233',
    value: 'GH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg',
    name: 'Ghana',
    number: 233,
  },
  {
    text: '+234',
    value: 'NG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg',
    name: 'Nigeria',
    number: 234,
  },
  {
    text: '+235',
    value: 'TD',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg',
    name: 'Chad',
    number: 235,
  },
  {
    text: '+236',
    value: 'CF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg',
    name: 'Central African Republic',
    number: 236,
  },
  {
    text: '+237',
    value: 'CM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg',
    name: 'Cameroon',
    number: 237,
  },
  {
    text: '+238',
    value: 'CV',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg',
    name: 'Cape Verde',
    number: 238,
  },
  {
    text: '+239',
    value: 'ST',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg',
    name: 'Sao Tome and Principe',
    number: 239,
  },
  {
    text: '+240',
    value: 'GQ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg',
    name: 'Equatorial Guinea',
    number: 240,
  },
  {
    text: '+241',
    value: 'GA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg',
    name: 'Gabon',
    number: 241,
  },
  {
    text: '+242',
    value: 'CG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg',
    name: 'Congo',
    number: 242,
  },
  {
    text: '+243',
    value: 'CD',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg',
    name: 'Congo, the Democratic Republic of the',
    number: 243,
  },
  {
    text: '+244',
    value: 'AO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg',
    name: 'Angola',
    number: 244,
  },
  {
    text: '+245',
    value: 'GW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg',
    name: 'Guinea-Bissau',
    number: 245,
  },
  {
    text: '+246',
    value: 'IO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg',
    name: 'British Indian Ocean Territory',
    number: 246,
  },
  {
    text: '+248',
    value: 'SC',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg',
    name: 'Seychelles',
    number: 248,
  },
  {
    text: '+249',
    value: 'SD',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg',
    name: 'Sudan',
    number: 249,
  },
  {
    text: '+250',
    value: 'RW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg',
    name: 'Rwanda',
    number: 250,
  },
  {
    text: '+251',
    value: 'ET',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg',
    name: 'Ethiopia',
    number: 251,
  },
  {
    text: '+252',
    value: 'SO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg',
    name: 'Somalia',
    number: 252,
  },
  {
    text: '+253',
    value: 'DJ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg',
    name: 'Djibouti',
    number: 253,
  },
  {
    text: '+254',
    value: 'KE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg',
    name: 'Kenya',
    number: 254,
  },
  {
    text: '+255',
    value: 'TZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg',
    name: 'Tanzania, United Republic of',
    number: 255,
  },
  {
    text: '+256',
    value: 'UG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg',
    name: 'Uganda',
    number: 256,
  },
  {
    text: '+257',
    value: 'BI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg',
    name: 'Burundi',
    number: 257,
  },
  {
    text: '+258',
    value: 'MZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg',
    name: 'Mozambique',
    number: 258,
  },
  {
    text: '+260',
    value: 'ZM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg',
    name: 'Zambia',
    number: 260,
  },
  {
    text: '+261',
    value: 'MG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg',
    name: 'Madagascar',
    number: 261,
  },
  {
    text: '+262',
    value: 'YT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg',
    name: 'Mayotte',
    number: 262,
  },
  {
    text: '+262',
    value: 'RE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg',
    name: 'Réunion',
    number: 262,
  },
  {
    text: '+263',
    value: 'ZW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg',
    name: 'Zimbabwe',
    number: 263,
  },
  {
    text: '+264',
    value: 'NA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg',
    name: 'Namibia',
    number: 264,
  },
  {
    text: '+265',
    value: 'MW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg',
    name: 'Malawi',
    number: 265,
  },
  {
    text: '+266',
    value: 'LS',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg',
    name: 'Lesotho',
    number: 266,
  },
  {
    text: '+267',
    value: 'BW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg',
    name: 'Botswana',
    number: 267,
  },
  {
    text: '+268',
    value: 'SZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg',
    name: 'Swaziland',
    number: 268,
  },
  {
    text: '+269',
    value: 'KM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg',
    name: 'Comoros',
    number: 269,
  },
  {
    text: '+290',
    value: 'SH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    number: 290,
  },
  {
    text: '+291',
    value: 'ER',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg',
    name: 'Eritrea',
    number: 291,
  },
  {
    text: '+297',
    value: 'AW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg',
    name: 'Aruba',
    number: 297,
  },
  {
    text: '+298',
    value: 'FO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg',
    name: 'Faroe Islands',
    number: 298,
  },
  {
    text: '+299',
    value: 'GL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg',
    name: 'Greenland',
    number: 299,
  },
  {
    text: '+350',
    value: 'GI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg',
    name: 'Gibraltar',
    number: 350,
  },
  {
    text: '+351',
    value: 'PT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg',
    name: 'Portugal',
    number: 351,
  },
  {
    text: '+352',
    value: 'LU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg',
    name: 'Luxembourg',
    number: 352,
  },
  {
    text: '+353',
    value: 'IE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg',
    name: 'Ireland',
    number: 353,
  },
  {
    text: '+354',
    value: 'IS',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg',
    name: 'Iceland',
    number: 354,
  },
  {
    text: '+355',
    value: 'AL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg',
    name: 'Albania',
    number: 355,
  },
  {
    text: '+356',
    value: 'MT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg',
    name: 'Malta',
    number: 356,
  },
  {
    text: '+357',
    value: 'CY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg',
    name: 'Cyprus',
    number: 357,
  },
  {
    text: '+358',
    value: 'FI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg',
    name: 'Finland',
    number: 358,
  },
  {
    text: '+359',
    value: 'BG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
    name: 'Bulgaria',
    number: 359,
  },
  {
    text: '+370',
    value: 'LT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg',
    name: 'Lithuania',
    number: 370,
  },
  {
    text: '+371',
    value: 'LV',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg',
    name: 'Latvia',
    number: 371,
  },
  {
    text: '+372',
    value: 'EE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg',
    name: 'Estonia',
    number: 372,
  },
  {
    text: '+373',
    value: 'MD',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg',
    name: 'Moldova, Republic of',
    number: 373,
  },
  {
    text: '+374',
    value: 'AM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg',
    name: 'Armenia',
    number: 374,
  },
  {
    text: '+375',
    value: 'BY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg',
    name: 'Belarus',
    number: 375,
  },
  {
    text: '+376',
    value: 'AD',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg',
    name: 'Andorra',
    number: 376,
  },
  {
    text: '+377',
    value: 'MC',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg',
    name: 'Monaco',
    number: 377,
  },
  {
    text: '+378',
    value: 'SM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg',
    name: 'San Marino',
    number: 378,
  },
  {
    text: '+379',
    value: 'VA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg',
    name: 'Holy See (Vatican City State)',
    number: 379,
  },
  {
    text: '+380',
    value: 'UA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg',
    name: 'Ukraine',
    number: 380,
  },
  {
    text: '+381',
    value: 'RS',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg',
    name: 'Serbia',
    number: 381,
  },
  {
    text: '+382',
    value: 'ME',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg',
    name: 'Montenegro',
    number: 382,
  },
  {
    text: '+385',
    value: 'HR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg',
    name: 'Croatia',
    number: 385,
  },
  {
    text: '+386',
    value: 'SI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg',
    name: 'Slovenia',
    number: 386,
  },
  {
    text: '+387',
    value: 'BA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg',
    name: 'Bosnia and Herzegovina',
    number: 387,
  },
  {
    text: '+389',
    value: 'MK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg',
    name: 'Macedonia, the Former Yugoslav Republic of',
    number: 389,
  },
  {
    text: '+420',
    value: 'CZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg',
    name: 'Czech Republic',
    number: 420,
  },
  {
    text: '+421',
    value: 'SK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg',
    name: 'Slovakia',
    number: 421,
  },
  {
    text: '+423',
    value: 'LI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg',
    name: 'Liechtenstein',
    number: 423,
  },
  {
    text: '+500',
    value: 'FK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg',
    name: 'Falkland Islands (Malvinas)',
    number: 500,
  },
  {
    text: '+501',
    value: 'BZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg',
    name: 'Belize',
    number: 501,
  },
  {
    text: '+502',
    value: 'GT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg',
    name: 'Guatemala',
    number: 502,
  },
  {
    text: '+503',
    value: 'SV',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg',
    name: 'El Salvador',
    number: 503,
  },
  {
    text: '+504',
    value: 'HN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg',
    name: 'Honduras',
    number: 504,
  },
  {
    text: '+505',
    value: 'NI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg',
    name: 'Nicaragua',
    number: 505,
  },
  {
    text: '+506',
    value: 'CR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg',
    name: 'Costa Rica',
    number: 506,
  },
  {
    text: '+507',
    value: 'PA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg',
    name: 'Panama',
    number: 507,
  },
  {
    text: '+508',
    value: 'PM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg',
    name: 'Saint Pierre and Miquelon',
    number: 508,
  },
  {
    text: '+509',
    value: 'HT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg',
    name: 'Haiti',
    number: 509,
  },
  {
    text: '+590',
    value: 'GP',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg',
    name: 'Guadeloupe',
    number: 590,
  },
  {
    text: '+590',
    value: 'BL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg',
    name: 'Saint Barthélemy',
    number: 590,
  },
  {
    text: '+590',
    value: 'MF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg',
    name: 'Saint Martin (French part)',
    number: 590,
  },
  {
    text: '+591',
    value: 'BO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg',
    name: 'Bolivia, Plurinational State of',
    number: 591,
  },
  {
    text: '+592',
    value: 'GY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg',
    name: 'Guyana',
    number: 592,
  },
  {
    text: '+593',
    value: 'EC',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg',
    name: 'Ecuador',
    number: 593,
  },
  {
    text: '+594',
    value: 'GF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg',
    name: 'French Guiana',
    number: 594,
  },
  {
    text: '+595',
    value: 'PY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg',
    name: 'Paraguay',
    number: 595,
  },
  {
    text: '+596',
    value: 'MQ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg',
    name: 'Martinique',
    number: 596,
  },
  {
    text: '+597',
    value: 'SR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg',
    name: 'Suriname',
    number: 597,
  },
  {
    text: '+598',
    value: 'UY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg',
    name: 'Uruguay',
    number: 598,
  },
  {
    text: '+599',
    value: 'BQ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg',
    name: 'Bonaire, Sint Eustatius and Saba',
    number: 599,
  },
  {
    text: '+599',
    value: 'CW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg',
    name: 'Curaçao',
    number: 599,
  },
  {
    text: '+599',
    value: 'SX',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg',
    name: 'Sint Maarten (Dutch part)',
    number: 599,
  },
  {
    text: '+670',
    value: 'TL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg',
    name: 'Timor-Leste',
    number: 670,
  },
  {
    text: '+672',
    value: 'NF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg',
    name: 'Norfolk Island',
    number: 672,
  },
  {
    text: '+673',
    value: 'BN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg',
    name: 'Brunei Darussalam',
    number: 673,
  },
  {
    text: '+674',
    value: 'NR',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg',
    name: 'Nauru',
    number: 674,
  },
  {
    text: '+675',
    value: 'PG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg',
    name: 'Papua New Guinea',
    number: 675,
  },
  {
    text: '+676',
    value: 'TO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg',
    name: 'Tonga',
    number: 676,
  },
  {
    text: '+677',
    value: 'SB',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg',
    name: 'Solomon Islands',
    number: 677,
  },
  {
    text: '+678',
    value: 'VU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg',
    name: 'Vanuatu',
    number: 678,
  },
  {
    text: '+679',
    value: 'FJ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg',
    name: 'Fiji',
    number: 679,
  },
  {
    text: '+680',
    value: 'PW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg',
    name: 'Palau',
    number: 680,
  },
  {
    text: '+681',
    value: 'WF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg',
    name: 'Wallis and Futuna',
    number: 681,
  },
  {
    text: '+682',
    value: 'CK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg',
    name: 'Cook Islands',
    number: 682,
  },
  {
    text: '+683',
    value: 'NU',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg',
    name: 'Niue',
    number: 683,
  },
  {
    text: '+685',
    value: 'WS',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg',
    name: 'Samoa',
    number: 685,
  },
  {
    text: '+686',
    value: 'KI',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg',
    name: 'Kiribati',
    number: 686,
  },
  {
    text: '+687',
    value: 'NC',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg',
    name: 'New Caledonia',
    number: 687,
  },
  {
    text: '+688',
    value: 'TV',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg',
    name: 'Tuvalu',
    number: 688,
  },
  {
    text: '+689',
    value: 'PF',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg',
    name: 'French Polynesia',
    number: 689,
  },
  {
    text: '+690',
    value: 'TK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg',
    name: 'Tokelau',
    number: 690,
  },
  {
    text: '+691',
    value: 'FM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg',
    name: 'Micronesia, Federated States of',
    number: 691,
  },
  {
    text: '+692',
    value: 'MH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg',
    name: 'Marshall Islands',
    number: 692,
  },
  {
    text: '+850',
    value: 'KP',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg',
    name: "Korea, Democratic People's Republic of",
    number: 850,
  },
  {
    text: '+852',
    value: 'HK',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg',
    name: 'Hong Kong',
    number: 852,
  },
  {
    text: '+853',
    value: 'MO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg',
    name: 'Macao',
    number: 853,
  },
  {
    text: '+855',
    value: 'KH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg',
    name: 'Cambodia',
    number: 855,
  },
  {
    text: '+856',
    value: 'LA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg',
    name: "Lao People's Democratic Republic",
    number: 856,
  },
  {
    text: '+870',
    value: 'PN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg',
    name: 'Pitcairn',
    number: 870,
  },
  {
    text: '+880',
    value: 'BD',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg',
    name: 'Bangladesh',
    number: 880,
  },
  {
    text: '+886',
    value: 'TW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg',
    name: 'Taiwan, Province of China',
    number: 886,
  },
  {
    text: '+960',
    value: 'MV',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg',
    name: 'Maldives',
    number: 960,
  },
  {
    text: '+961',
    value: 'LB',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg',
    name: 'Lebanon',
    number: 961,
  },
  {
    text: '+962',
    value: 'JO',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg',
    name: 'Jordan',
    number: 962,
  },
  {
    text: '+963',
    value: 'SY',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg',
    name: 'Syrian Arab Republic',
    number: 963,
  },
  {
    text: '+964',
    value: 'IQ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg',
    name: 'Iraq',
    number: 964,
  },
  {
    text: '+965',
    value: 'KW',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg',
    name: 'Kuwait',
    number: 965,
  },
  {
    text: '+966',
    value: 'SA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg',
    name: 'Saudi Arabia',
    number: 966,
  },
  {
    text: '+967',
    value: 'YE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg',
    name: 'Yemen',
    number: 967,
  },
  {
    text: '+968',
    value: 'OM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg',
    name: 'Oman',
    number: 968,
  },
  {
    text: '+970',
    value: 'PS',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg',
    name: 'Palestine, State of',
    number: 970,
  },
  {
    text: '+971',
    value: 'AE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
    name: 'United Arab Emirates',
    number: 971,
  },
  {
    text: '+972',
    value: 'IL',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg',
    name: 'Israel',
    number: 972,
  },
  {
    text: '+973',
    value: 'BH',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg',
    name: 'Bahrain',
    number: 973,
  },
  {
    text: '+974',
    value: 'QA',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg',
    name: 'Qatar',
    number: 974,
  },
  {
    text: '+975',
    value: 'BT',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg',
    name: 'Bhutan',
    number: 975,
  },
  {
    text: '+976',
    value: 'MN',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg',
    name: 'Mongolia',
    number: 976,
  },
  {
    text: '+977',
    value: 'NP',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg',
    name: 'Nepal',
    number: 977,
  },
  {
    text: '+992',
    value: 'TJ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg',
    name: 'Tajikistan',
    number: 992,
  },
  {
    text: '+993',
    value: 'TM',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg',
    name: 'Turkmenistan',
    number: 993,
  },
  {
    text: '+994',
    value: 'AZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg',
    name: 'Azerbaijan',
    number: 994,
  },
  {
    text: '+995',
    value: 'GE',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg',
    name: 'Georgia',
    number: 995,
  },
  {
    text: '+996',
    value: 'KG',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg',
    name: 'Kyrgyzstan',
    number: 996,
  },
  {
    text: '+998',
    value: 'UZ',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg',
    name: 'Uzbekistan',
    number: 998,
  },
]

export const COUNTRIES_JSON_URL = 'https://fanaty-general-ohio.s3.us-east-2.amazonaws.com/ticket-2780/countries.json'
export const STATES_JSON_URL = 'https://fanaty-general-ohio.s3.us-east-2.amazonaws.com/ticket-2780/states.json'
export const CITIES_JSON_URL = 'https://fanaty-general-ohio.s3.us-east-2.amazonaws.com/ticket-2780/cities.json'

export const SUPPORT_NUMBER = '+18889653262'
export const SUPPORT_NUMBER_TEXT = '(888) 965-3262'
