export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    configOrganization: localStorage.getItem('config-organization')
      ? parseInt(localStorage.getItem('config-organization'), 10)
      : 0,
    configFacility: localStorage.getItem('config-facility')
      ? parseInt(localStorage.getItem('config-facility'), 10)
      : 0,
    configFilters: localStorage.getItem('config-filters')
      ? localStorage.getItem('config-filters')
      : null,
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value
    },
    UPDATE_ORGANIZATION(state, value) {
      state.configOrganization = value
    },
    UPDATE_FACILITY(state, value) {
      state.configFacility = value
    },
    UPDATE_FILTERS(state, value) {
      state.configFilters = value
    },
    CLEAR_CONFIG(state) {
      state.configOrganization = 0
      state.configFacility = 0
    },
  },
  actions: {
    async clearConfigAction({ commit }) {
      commit('CLEAR_CONFIG')
    },
  },
}
