import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

Vue.use(Zendesk, {
  key: 'fJqGXJm0Cf953vaXSw79ljy4VaNqbqTcuB9NGwsn',
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#78a300',
      },
    },
  },
})
