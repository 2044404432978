/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountMultipleOutline,
  mdiCurrencyUsd,
  mdiBell,
  mdiPackageVariantClosed,
  mdiDumpTruck,
  mdiFinance,
  mdiAccountGroup,
  mdiPackageUp,
  mdiLiquidSpot,
  mdiAccountMultiple,
  mdiFileDocumentMultipleOutline,
  mdiCarMultiple,
  mdiCardAccountDetailsOutline,
  mdiAccountSwitchOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useCryptoJs from '@/@core/utils/useCryptoJs'

export default function usePermissions() {
  const { userData } = useCryptoJs()
  const PERMISSIONS = ref([
    {
      id: 1,
      title: 'Despachos',
      icon: mdiDumpTruck,
    },
    {
      id: 2,
      title: 'Productos',
      icon: mdiPackageVariantClosed,
    },
    {
      id: 3,
      title: 'Proveedores',
      icon: mdiAccountGroup,
    },
    {
      id: 4,
      title: 'Carga de Inventario de Productos',
      icon: mdiPackageUp,
    },
    {
      id: 5,
      title: 'Tipos de Mezclas',
      icon: mdiLiquidSpot,
    },
    {
      id: 6,
      title: 'Clientes',
      icon: mdiAccountMultiple,
    },
    {
      id: 7,
      title: 'Proyectos',
      icon: mdiFileDocumentMultipleOutline,
    },
    {
      id: 8,
      title: 'Vehículos',
      icon: mdiCarMultiple,
    },
    {
      id: 9,
      title: 'Conductores',
      icon: mdiCardAccountDetailsOutline,
    },
    {
      id: 10,
      title: 'Pagos',
      icon: mdiCurrencyUsd,
    },
    {
      id: 11,
      title: 'Roles',
      icon: mdiAccountSwitchOutline,
    },
    {
      id: 12,
      title: 'Usuarios',
      icon: mdiAccountMultipleOutline,
    },
    {
      id: 13,
      title: 'Notificaciones',
      icon: mdiBell,
    },
    {
      id: 14,
      title: 'Reports',
      icon: mdiFinance,
    },
  ])

  const getPermissionsGroup = permissions => {
    const groupPermissions = permissions.reduce((groups, item) => {
      const group = (groups[item.view_id] || [])
      group.push(item)
      groups[item.view_id] = group

      return groups
    }, {})

    const mapPermissions = PERMISSIONS.value.map(e => {
      return {
        ...e,
        permissions: groupPermissions[e.id],
      }
    })

    return mapPermissions
  }

  const hasPermission = id => userData.value.permissions_ids.includes(id)

  const hasActionPermission = (idShow, idEdit, idDelete) => {
    return userData.value.permissions_ids.includes(idShow)
    || userData.value.permissions_ids.includes(idEdit)
    || userData.value.permissions_ids.includes(idDelete)
  }

  return {
    PERMISSIONS,
    userData,

    getPermissionsGroup,
    hasPermission,
    hasActionPermission,
  }
}
