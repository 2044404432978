<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="isDark"
      @click="updateTheme"
    >
      {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  setup() {
    const { isDark, appSkinVariant } = useAppConfig()

    const updateTheme = () => {
      isDark.value = !isDark.value
      if (isDark.value) appSkinVariant.value = 'bordered'
      else appSkinVariant.value = 'default'
    }

    return {
      isDark,
      updateTheme,

      // Icons
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style>
</style>
