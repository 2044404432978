import { apiHttp } from '../axiosApi'
import {
  productPath,
  productAll,
  productAllActive,
  productAllActiveClient,
} from '../config/apiRoute'

export const getProducts = async () => apiHttp('GET', `${productAll}`)
export const getProductsActive = () => apiHttp('GET', `${productAllActive}`)
export const getProduct = async productId => apiHttp('GET', `${productPath}${productId}`)
export const createProduct = async body => apiHttp('POST', `${productPath}`, body)
export const editProduct = async (body, productId) => apiHttp('PUT', `${productPath}${productId}`, body)
export const deleteProduct = async id => apiHttp('DELETE', `${productPath}${id}`)
export const getProductUnits = () => apiHttp('GET', `${productPath}units/`)
export const getProductsActiveClient = clientId => apiHttp('GET', `${productAllActiveClient}${clientId}`)
