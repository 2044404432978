/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
import {
  dashboardOrderSummary,
  dashboardGroupOrderSummary,
  dashboardHighlightSummary,
  dashboardActives,
  dashboardUser,
  dashboardUserFacility,
  dashboardVideoViews,
  dashboardFacilitySales,
  dashboardFacilityRevenue,
  dashboardUserRegistered,
} from '../config/apiRoute'

export const getDashboardGroupOrderSummary = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardOrderSummary}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardOrderSummary = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardGroupOrderSummary}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardHighlightSummary = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardHighlightSummary}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardActives = async (groupId, facilityId) => apiHttp('GET', `${dashboardActives}${groupId}/${facilityId}`)
export const getDashboardUser = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardUser}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardUserFacility = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardUserFacility}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardVideoViews = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardVideoViews}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardFacilitySales = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardFacilitySales}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardFacilityRevenue = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardFacilityRevenue}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
export const getDashboardUserRegistered = async (groupId, facilityId, dateRange, startDate, endDate) => apiHttp('GET', `${dashboardUserRegistered}${groupId}/${facilityId}/${dateRange}/${startDate}/${endDate}`)
