// Axios Mock Adapter
import '@/@fake-db/db'
import Vue from 'vue'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-zendesk'
import '@/plugins/loading'
import '@/plugins/toasted'
import '@/plugins/vue-google-maps'
import '@/plugins/vue-social-sharing'
import '@/plugins/vue-video-player'
import '@/plugins/vue-excel-xlsx'
import '@/plugins/vue-json-excel'
import '@/plugins/vue-countdown'
import '@/plugins/sweetalert2'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
