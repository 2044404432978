import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
  mdiSoccer,
  mdiSoccerField,
  mdiWifi,
  mdiWifiOff,
  mdiCamera,
  mdiCameraOff,
  mdiCameraSwitch,
  mdiVideo,
  mdiVideoOff,
  mdiVideoSwitch,
  mdiTemperatureCelsius,
  mdiBatteryAlert,
  mdiTrophy,
  mdiVanUtility,
} from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'
import { getConsoleNotifications } from '@api'
import { useUtils } from '@core/libs/i18n'
import usePermissions from '@core/utils/usePermissions'

export default function useConsoleNotificationsList() {
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const listTable = ref([])

  const tableColumns = computed(() => [
    { text: t('cameras.name').toUpperCase(), value: 'name', show: true },
    { text: t('cameras.mac_address').toUpperCase(), value: 'mac_address', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(27, 29, 30),
    },
  ])

  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const totalListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['field.facility.name'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  // fetch data
  const fetchConsoleNotification = async () => {
    const response = await getConsoleNotifications()

    if (response.ok) {
      const filteredData = response.data.filter(
        item =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (item.notification_text && item.notification_text.toLowerCase().includes(searchQuery.value)) ||
          (item.notification_title && item.notification_title.toLowerCase().includes(searchQuery.value)) ||
          (item.html_code && item.html_code.toLowerCase().includes(searchQuery.value)),
      )

      listTable.value = filteredData
      totalListTable.value = response.data.length
    } else {
      listTable.value = []
      totalListTable.value = 0
    }
    loading.value = false
  }

  watch([searchQuery, roleFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchConsoleNotification()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCameraRoleVariant = role => {
    if (role === 'subscriber' || role === 'F') return 'info'
    if (role === 'author' || role === 'R') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin' || role === 'A') return 'error'

    return 'primary'
  }

  const resolveCameraRoleIcon = role => {
    if (role === 'subscriber' || role === 'F') return mdiAccountOutline
    if (role === 'author' || role === 'R') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin' || role === 'A') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveCameraStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveCameraTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const resolveCameraRoleText = role => {
    if (role === 'subscriber' || role === 'F') return 'Facility'
    if (role === 'author' || role === 'R') return 'Representative'
    if (role === 'maintainer') return 'Maintainer'
    if (role === 'editor') return 'Editor'
    if (role === 'admin' || role === 'A') return 'Administrator'

    return 'Administrator'
  }

  const resolveStatusText = status => {
    if (status === 'A') return t('status.active')
    if (status === 'I') return t('status.inactive')

    return t('status.active')
  }

  const resolveIcon = icon => {
    if (icon === 'mdi-soccer') return mdiSoccer
    if (icon === 'mdi-soccer-field') return mdiSoccerField
    if (icon === 'mdi-wifi') return mdiWifi
    if (icon === 'mdi-wifi-off') return mdiWifiOff
    if (icon === 'mdi-camera') return mdiCamera
    if (icon === 'mdi-camera-off') return mdiCameraOff
    if (icon === 'mdi-camera-switch') return mdiCameraSwitch
    if (icon === 'mdi-video') return mdiVideo
    if (icon === 'mdi-video-off') return mdiVideoOff
    if (icon === 'mdi-video-switch') return mdiVideoSwitch
    if (icon === 'mdi-temperature-celsius') return mdiTemperatureCelsius
    if (icon === 'mdi-battery-alert') return mdiBatteryAlert
    if (icon === 'mdi-trophy') return mdiTrophy
    if (icon === 'mdi-van-utility') return mdiVanUtility

    return null
  }

  return {
    listTable,
    tableColumns,
    computedTableColumns,
    searchQuery,
    roleFilter,
    statusFilter,
    totalListTable,
    loading,
    options,
    selectedRows,

    t,
    fetchConsoleNotification,

    resolveCameraRoleVariant,
    resolveCameraRoleIcon,
    resolveCameraStatusVariant,
    resolveCameraTotalIcon,
    resolveStatusText,
    resolveCameraRoleText,
    resolveIcon,
    hasPermission,
  }
}
