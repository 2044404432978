import * as VueGoogleMaps from 'vue2-google-maps'
import * as LocationPicker from 'vue2-location-picker'
import Vue from 'vue'

Vue.use(LocationPicker, {
  installComponents: true, // If true, create it globally
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBIMheCyv_x_ZvNkoBbFwM2uspgBdNmYz8',
    libraries: 'places',
  },
  autobindAllEvents: true,
})
