import { apiHttp } from '../axiosApi'
import {
  mixTypePath, mixTypeAll, mixTypeAllActive, mixTypeProduct,
} from '../config/apiRoute'

export const getMixTypes = async () => apiHttp('GET', `${mixTypeAll}`)
export const getMixTypesActive = () => apiHttp('GET', `${mixTypeAllActive}`)
export const getMixType = async mixTypeId => apiHttp('GET', `${mixTypePath}${mixTypeId}`)
export const createMixType = async body => apiHttp('POST', `${mixTypePath}`, body)
export const editMixType = async (body, mixTypeId) => apiHttp('PUT', `${mixTypePath}${mixTypeId}`, body)
export const deleteMixType = async id => apiHttp('DELETE', `${mixTypePath}${id}`)
export const createMixTypeProduct = async body => apiHttp('POST', `${mixTypeProduct}`, body)
export const deleteMixTypeProduct = async (id, productId) => apiHttp('DELETE', `${mixTypeProduct}${id}/${productId}`)
