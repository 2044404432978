import {

  // mdiAbTesting,

  mdiCurrencyUsd,
  mdiCardAccountDetailsOutline,
  mdiBell,
  mdiAccountMultipleOutline,
  mdiFinance,
  mdiPackageVariantClosed,
  mdiDumpTruck,
  mdiLiquidSpot,
  mdiPackageUp,
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiCarMultiple,
  mdiFileDocumentMultipleOutline,
  mdiAccountSwitchOutline,
} from '@mdi/js'

export default [
  // Despachos
  {
    id: [1, 6],
    subheader: 'Gestión de Despachos',
  },
  {
    id: 6, // 1,
    title: 'Despachos',
    icon: mdiDumpTruck,
    to: 'views-shipping-list',
  },
  {
    id: 14,
    title: 'Reports',
    icon: mdiFinance,
    to: 'views-reports-view',
  },

  // Productos
  {
    id: [2, 3, 4, 5, 6],
    subheader: 'Gestión de Productos',
  },
  {
    id: 6, // 2,
    title: 'Productos',
    icon: mdiPackageVariantClosed,
    to: 'views-product-list',
  },
  {
    id: 3,
    title: 'Proveedores',
    icon: mdiAccountGroup,
    to: 'views-order-history-list',
  },
  {
    id: 4,
    title: 'Carga de Inventario de Productos',
    icon: mdiPackageUp,
    to: 'views-order-history-list',
  },
  {
    id: 5,
    title: 'Tipos de Mezclas',
    icon: mdiLiquidSpot,
    to: 'views-mix-type-list',
  },

  // ----------Clientes---------------------
  {
    id: [6, 7, 8, 9, 10],
    subheader: 'Gestión de Clientes',
  },
  {
    id: 6,
    title: 'Clientes',
    icon: mdiAccountMultiple,
    to: 'views-client-list',
  },
  {
    id: 7,
    title: 'Obras',
    icon: mdiFileDocumentMultipleOutline,
    to: 'views-construction-site-list',
  },
  {
    id: 8,
    title: 'Vehículos',
    icon: mdiCarMultiple,
    to: 'views-vehicle-list',
  },
  {
    id: 9,
    title: 'Conductores',
    icon: mdiCardAccountDetailsOutline,
    to: 'views-driver-list',
  },
  {
    id: 10,
    title: 'Pagos',
    icon: mdiCurrencyUsd,
    to: 'views-payment-list',
  },

  // ----------Seguridad---------------------
  {
    id: [11, 12, 13],
    subheader: 'Gestión de Seguridad',
  },
  {
    id: 11,
    title: 'Roles',
    icon: mdiAccountSwitchOutline,
    to: 'views-role-list',
  },
  {
    id: 12,
    title: 'Usuarios',
    icon: mdiAccountMultipleOutline,
    to: 'views-user-list',
  },
  {
    id: 13,
    title: 'Notificaciones',
    icon: mdiBell,
    to: 'views-console-notification-list',
  },

  // ----------Test---------------------
  // {
  //   id: [11],
  //   subheader: 'Test',
  // },
  // {
  //   id: 11,
  //   title: 'Basic',
  //   icon: mdiAbTesting,
  //   to: 'views-basic-list',
  // },
]
