// Auth
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const UPDATE_PERSON = 'UPDATE_PERSON'
export const REFRESH_SESSION = 'REFRESH_SESSION'

// App
export const SET_APP = 'SET_APP'
export const CLEAN_APP = 'CLEAN_APP'
export const TITLE_APP = 'TITLE_APP'
export const TITLE_BUTTON = 'TITLE_BUTTON'
